.App {
  text-align: center;
  font-family: Arial, Helvetica, sans-serif
}

.App-logo {
  height: 60vmin;
  width: 60vmin;
  max-width: 40vh;
  max-height: 40vh;
  pointer-events: none;
}

.App-header {
  background: #021121;
  background: linear-gradient(150deg, #424242 0%, #1b1a1a 83%, #131313 100%);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

h1 {
  text-shadow: 3px 3px 3px rgba(0,0,0,.5);
  margin: 10px;
}
